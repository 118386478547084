/**
 * @module pieChart
 * @description Draws Pie charts
 */
import {createChart} from '../modules/chartHelper.js';
import {getDefaultPieConfig} from './defaultChartConfigs.js';

class PieChart extends HTMLElement {
    /** Called each time the element is added to the document. */
    connectedCallback() {
        const canvas = this.querySelector('canvas');
        const data = {
            labels: JSON.parse(this.getAttribute('labels')),
            datasets: JSON.parse(this.getAttribute('datasets')),
        };
        const configuration = {
            ...getDefaultPieConfig(),
            data,
        };

        const options = JSON.parse(this.getAttribute('options'));
        options.tooltipConfigName = this.getAttribute('tooltips');

        createChart(canvas, configuration, options);
    }
}

customElements.define('pie-chart', PieChart);
